import { useState } from 'react';
import { Button, ButtonBase, Container } from '@material-ui/core';
import NavButton from '../NavButton';
import { makeStyles } from '@material-ui/core/styles';
import { LocalHospital, LocalPharmacy, School, DirectionsRun, GetApp, AccessTime } from '@material-ui/icons';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import MapIcon from '@material-ui/icons/Map';
import DownloadIcon from '@mui/icons-material/Download';
import { Redirect } from 'react-router-dom';
import styles from '../../styles';
import { useReactPWAInstall } from 'react-pwa-install';
import GrowLogo2 from '../../Media/Grow-logo-2.png'
import WaveWhite from '../../Media/wave-white.png'
import WaveWhite2 from '../../Media/wave-white-2.png'
import MarkdownView from 'react-showdown';
import { marked } from 'marked';
import ReactGA from 'react-ga4';


const useStyles = makeStyles(styles);

const Landing = (props) => {
    
    const [reroute, setReroute] = useState(null);
    const classes = useStyles();
    
    // console.log(props.content[0])
    
    const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
    
    const handleRedirect = event => {
        setReroute(event.currentTarget.name);
        window.scrollTo(0,0)
    }
    
    const handleInstall = () => {
        pwaInstall({
            title: props.content[0].installWindow.title,
            features: (
                <ul>
                    {props.content[0].installWindow.features.map((c) => {
                        return <li>{c}</li>
                    })}
                </ul>
            ),
            description: props.content[0].installWindow.description,
        })
        .then(() => alert("App installed successfully or instructions for install shown"))
        .catch(() => alert("Install cancelled"));
    };

    //Show nothing if content hasn't loaded yet
    if(!props.content) {
        return <></>
    } 
    
    
    //manipulate button arrays that need to be regrouped
    let educationalInfoButtons = []
    for(let i = 0; i<props.content[0].educationalInfo.buttons.length; i+=2) {
        educationalInfoButtons.push([])

        if(props.content[0].educationalInfo.buttons[i]) educationalInfoButtons[i/2].push(props.content[0].educationalInfo.buttons[i])
        if(props.content[0].educationalInfo.buttons[i+1]) educationalInfoButtons[i/2].push(props.content[0].educationalInfo.buttons[i+1])
    }
    
    let infoForProfessionalsButtons = []
    for(let i = 0; i<props.content[0].informationForProfessionals.buttons.length; i+=2) {
        infoForProfessionalsButtons.push([])

        if(props.content[0].informationForProfessionals.buttons[i]) infoForProfessionalsButtons[i/2].push(props.content[0].informationForProfessionals.buttons[i])
        if(props.content[0].informationForProfessionals.buttons[i+1]) infoForProfessionalsButtons[i/2].push(props.content[0].informationForProfessionals.buttons[i+1])
    }

    // ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });
    // console.log("SENT")
    
    return(
        reroute ?
        <Redirect push to={reroute}/>
        :
        <>
            <div class="home-header-area">
                <div class="background-section-1">
                    <img src={GrowLogo2} alt="Grow Logo" class="grow-logo-landing"/>
                    <h1 class="sr-only">GROW App</h1>
                </div> {/*- .background-section-1 -*/}

                <div class="section-1-white"></div> {/*- NOTE: This is 1 of the 2 slants on the homepage header. -*/}
                <div class="section-2-transparent-white"></div> {/*- NOTE: This is 1 of the 2 slants on the homepage header. -*/}
            </div>	 {/*- .home-header-area-*/}
            
            <div class="safety-info-area-title">
                <Container maxWidth="md">
                    <pre>
                        <h2>{props.content[0].safety.header}</h2> 
                    </pre>
                </Container>
            </div> {/*- .safety-info-area-title -*/}
                
            <div class="safety-area">
                <Container maxWidth="md">
                    <div class="flex-content content-area-1-row">
                    <div>
                        <a name={'/' + props.content[0].safety.buttonData.path1} onClick={(e) => handleRedirect(e)}> 
                            <i class="fa-solid fa-circle-exclamation"></i>
                            <p>{props.content[0].safety.buttonData.text1}</p>
                        </a>
                    </div> {/*- This div provides padding around the area */}
                    <div>
                        <a name={'/' + props.content[0].safety.buttonData.path2} onClick={(e) => handleRedirect(e)}> 
                            <i class="fa-solid fa-square-plus icon-dark-outline"></i>
                            <p>{props.content[0].safety.buttonData.text2}</p>
                        </a>
                    </div> {/*- This div provides padding around the area */}
                    <div>
                        <a name={'/' + props.content[0].safety.buttonData.path3} onClick={(e) => handleRedirect(e)}> 
                            <i class="fa-solid fa-phone icon-dark-outline"></i>
                            <p>{props.content[0].safety.buttonData.text3}</p>
                        </a>
                    </div> {/*- This div provides padding around the area */}
                    </div> {/*- .flex-content -*/}
                </Container>
            </div> {/*- .safety-area -*/}
                
            <div class="professionals-header-area desktop-hide"> {/* Area hidden on desktop view for better flow. */}
                <img src={WaveWhite} alt="White Wave Separator" class="img-responsive minus-5-top" />
                <Container maxWidth="md">
                    <h2 class="professionals-header">{props.content[0].talkingToProfessionals.header}</h2>
                </Container>
            </div> {/*- .professionals-header-area -*/}

            <div class="professionals-area">
                <Container maxWidth="md">
                    <h2 class="desktop-show">{props.content[0].talkingToProfessionals.header}</h2> {/* Appears when on desktop view only so that header is still present. Otherwise not visible. */}
                    <p class="margin-50">
                        {/* <MarkdownView
                            markdown={props.content[0].talkingToProfessionals.blurb}
                            options={{openLinksInNewWindow: true, simpleLineBreaks: true}}
                        /> */}
                        {props.content[0].talkingToProfessionals.blurb ? <div dangerouslySetInnerHTML={{__html: marked.parse(props.content[0].talkingToProfessionals.blurb)}} /> : <></>}
                    </p>
                    <a name={'/' + props.content[0].talkingToProfessionals.buttonPath} onClick={(e) => handleRedirect(e)} class="home-button-clear center-button">
                        <p>{props.content[0].talkingToProfessionals.button}</p>
                    </a>  {/*- .home-button-clear -*/}
                </Container>
            </div>  {/*- .professionals-area -*/}
                
            <div class="content-area-services">
                <Container maxWidth="md">
                    <div class="flex-content icon-overlap-spacing">
                        <a name='/map' onClick={(e) => handleRedirect(e)} class="home-button-clear">
                            <i class="fa-solid fa-map-location-dot icon-white-outline"></i>
                            <p>{props.content[0].maps.button1}</p>
                        </a> {/*- .home-button-clear -*/}
                        <a name='/takeback-locations' onClick={(e) => handleRedirect(e)} class="home-button-clear">
                            <i class="fa-solid fa-hand-holding-medical icon-white-outline"></i>
                            <p>{props.content[0].maps.button2}</p>
                        </a> {/*- .home-button-clear -*/}
                    </div> {/*- .flex-content -*/}
                </Container>
            </div> {/*- .content-area-services -*/}

            <div class="content-area-education">
                <Container maxWidth="md">
                    <i class="fa-solid fa-lightbulb icon-overlap-spacing"></i>
                    <h2>{props.content[0].educationalInfo.header}</h2>
                    <p>
                        {/* <MarkdownView
                            markdown={props.content[0].educationalInfo.blurb}
                            options={{openLinksInNewWindow: true, simpleLineBreaks: true}}
                        /> */}
                        {props.content[0].educationalInfo.blurb ? <div dangerouslySetInnerHTML={{__html: marked.parse(props.content[0].educationalInfo.blurb)}} /> : <></> }
                    </p>
                    {
                        educationalInfoButtons.map((buttonGroup) => {
                            return(
                                <div class="flex-content">
                                    {
                                        buttonGroup.map((button) => {
                                            return(
                                                <a name={'/'+button.path} onClick={(e) => handleRedirect(e)} class="home-button-clear">
                                                    <h3 class="link-text-white">{button.text}</h3>
                                                    <p class="small-text">{button.subtext}</p>
                                                </a>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                    <i class="fa-solid fa-handshake-angle icon-dark-outline handshake-icon desktop-hide"></i> {/*- NOTE: This icon is positioned between the 2 areas, but in code contained in the top area. Hidden on desktop view. -*/}
                </Container>
            </div> {/*- .content-area-education -*/}
                
            <div class="tips-area">
                <Container maxWidth="md">
                    <h2 class="tips-header">{props.content[0].tips.header}</h2>
                    <div class="btn-group-vertical">
                        <p>
                            {/* <MarkdownView
                                markdown={props.content[0].tips.blurb}
                                options={{openLinksInNewWindow: true, simpleLineBreaks: true}}
                            /> */}
                            {props.content[0].tips.blurb ? <div dangerouslySetInnerHTML={{__html: marked.parse(props.content[0].tips.blurb)}} /> : <></>}
                        </p>
                    </div> {/*- .home-button-gradient -*/}
                    {props.content[0].tips.buttons.map((c) => {
                        return(
                            <div class="home-button-gradient">
                                <a name={'/'+c.path} onClick={(e) => handleRedirect(e)}><p>{c.text}</p></a>
                            </div>
                        )
                    })}
                </Container>
            </div> {/*- .tips-area -*/}

                <img src={WaveWhite2} alt="White Wave Separator" class="img-responsive minus-5-top desktop-hide" /> {/* Hidden on desktop view for better flow. */}
                
            <div class="information-area homepage-dark">
                <Container maxWidth="md">
                    <i class="fa-solid fa-clipboard-list"></i>
                    <h2>{props.content[0].informationForProfessionals.header}</h2>
                    <p class="margin-70">
                        {/* <MarkdownView
                            markdown={props.content[0].informationForProfessionals.blurb}
                            options={{openLinksInNewWindow: true, simpleLineBreaks: true}}
                        /> */}
                        {props.content[0].informationForProfessionals.blurb ? <div dangerouslySetInnerHTML={{__html: marked.parse(props.content[0].informationForProfessionals.blurb)}} /> : <></>}
                    </p>
                    {
                        infoForProfessionalsButtons.map((buttonGroup) => {
                            return(
                                <div class="flex-content">
                                    {
                                        buttonGroup.map((button) => {
                                            return(
                                                <a name={'/'+button.path} onClick={(e) => handleRedirect(e)} class="home-button-clear">
                                                    <p>{button.text}</p>
                                                </a>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </Container>
            </div>  {/*- .information-area -*/}
                
            <div class="about-area">
                <Container maxWidth="md">
                    <h2 class="text-center">{props.content[0].about.header}</h2>
                    <p class="margin-70">
                        {/* <MarkdownView
                            markdown={props.content[0].about.blurb}
                            options={{openLinksInNewWindow: true, simpleLineBreaks: true}}
                        /> */}
                        {props.content[0].about.blurb ? <div dangerouslySetInnerHTML={{__html: marked.parse(props.content[0].about.blurb)}} /> : <></>}
                    </p>
                    <a name={'/' + props.content[0].about.buttonPath} onClick={(e) => handleRedirect(e)} class="home-button-clear center-button button-gradient">
                        <p>{props.content[0].about.button}</p>
                    </a>{/*- .home-button-clear -*/}
                </Container>
            </div>{/*- .about-area -*/}
                
            <div>
                <h2 class="homepage-footer">{props.content[0].about.copyright}</h2>
                <a class="homepage-footer" href={"mailto:"+props.content[0].about.feedbackEmail+"?subject = GROW Feedback&body = Accessed from landing page. Please let us know what you think of the app!"}>
                        {props.content[0].about.feedbackText}
                </a>
                <Container maxWidth="md">
                    <a onClick={handleInstall} class="download-button-clear center-button">
                        <p>Make Available Offline</p>
                        <DownloadIcon/>
                    </a>{/*- .home-button-clear -*/}
                </Container>
            </div>{/*- .homepage-footer -*/}
                
        </>
        //     {supported() && !isInstalled() && (
        //         <Button className={classes.installButton} onClick={handleInstall}>
        //             <div className={classes.buttonContent}>
        //                 <GetApp fontSize='large'/>
        //                 <br/>
        //                 Make Available Offline
        //             </div>
        //         </Button>
        //     )}
        // </div>
    );
}

export default Landing;