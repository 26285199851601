import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import Geocode from "react-geocode";
import copy from "copy-to-clipboard";
// import _ from 'lodash';
import { useState } from 'react';
import purpleMarker from '../Media/purplemarker.png';
import redMarker from '../Media/redmarker.png';
import blueMarker from '../Media/bluemarker.png';
import personMarker from '../Media/personmarker.png'
import keys from '../keys';
import styles from '../styles';
import { Button, IconButton, Card, TextField, Select, InputLabel, MenuItem, Input, FormControl, Container } from '@material-ui/core';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SearchIcon from '@mui/icons-material/Search';
import InfoWindowEx from './InfoWindowEx.js';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@mui/material';

Geocode.setApiKey(keys.GOOGLE_MAPS_API_KEY);
const useStyles = makeStyles(styles);

/**
 * Displays a Google Map and the associated markers
 * as passed through the `markers` prop
 * 
 * @param {{markers:{name: string, address1: string, address2: string, phone: string, website: string: imageURL: string, county: string, type: string}[], showMarkers: boolean[]}} props
 */
const MapContainer = (props) => {
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [locationButtonEnabled, setLocationButtonEnabled] = useState(false);
    const [latitude, setLatitude] = useState(33.759644);
    const [longitude, setLongitude] = useState(-87.450391);
    const [filtersOpen, setFiltersOpen] = useState(false);

    const onMarkerClick = (props, marker, e) => {
        setSelectedMarker(marker);
        setShowInfoWindow(true);
    }

    const onMapClick = () => {
        setSelectedMarker(null);
        setShowInfoWindow(false);
    }

    const onLocationClick = () => {
        setLocationButtonEnabled(false);
        navigator.geolocation.getCurrentPosition((pos) =>{
            setLocationButtonEnabled(true);
            props.setCenter({lat: pos.coords.latitude, lng: pos.coords.longitude})
        });
    }

    const onCopyClick = (marker) => {
        if(marker) {
            copy(`${marker.address1}, ${marker.address2}`);
        }
    }

    let services = null
    if(!props.takeback) services = JSON.parse(localStorage.getItem('services'));
    else services = JSON.parse(localStorage.getItem('takeback'));

    const treatmentTypes = [];
    const populations = [];
    const insurances = [];
    services.map((c) => {
        let newVals = c.treatmentType ? c.treatmentType.filter((type) => !treatmentTypes.find((element) => element === type)) : [];
        treatmentTypes.push(...newVals);
        newVals = c.population ? c.population.filter((pop) => !populations.find((element) => element === pop)) : [];
        populations.push(...newVals);
        newVals = c.insurance ? c.insurance.filter((insure) => !insurances.find((element) => element === insure)) : [];
        insurances.push(...newVals);
        return null;
    });

    const classes = useStyles();
    const [selectedTreatmentTypes, setSelectedTreatmentTypes] = useState([]);
    const [selectedPopulations, setSelectedPopulations] = useState([]);
    const [selectedInsurances, setSelectedInsurances] = useState([]);

    const handleTreatmentTypesSelect = (newValue) => {
        setSelectedTreatmentTypes(newValue);
    };

    const handlePopulationsSelect = (newValue) => {
        setSelectedPopulations(newValue);
    };

    const handleInsurancesSelect = (newValue) => {
        setSelectedInsurances(newValue);
    };

    let filteredServices = services

    if(!props.takeback) {
        filteredServices = services.filter((service) => {
            var treatFlag = true;
            var popFlag = true;
            var insFlag = true;

            for (var x = 0; x < selectedTreatmentTypes.length; x++){
                treatFlag = (!(service.treatmentType && service.treatmentType.find((type) => selectedTreatmentTypes[x] === type))) ? false : true;
            }
            for (var y = 0; y < selectedPopulations.length; y++){
                popFlag = (!(service.population && service.population.find((pop) => selectedPopulations[y] === pop))) ? false : true;
            }
            for (var z = 0; z < selectedInsurances.length; z++){
                insFlag = (!(service.insurance && service.insurance.find((ins) => selectedInsurances[z] === ins))) ? false : true;
            }

            if (treatFlag && popFlag && insFlag){
                return true;
            }
            return false;
        });
    }

    const geolocation = navigator.geolocation;
console.log(props.center)
    return (
        <div style={styles.mapContainer} class="map">
            <Map
                zoomControl={false}
                google={window.google} 
                zoom={9}
                initialCenter={{  
                    lat: 33.759644,
                    lng: -87.450391
                }}
                center={props.center}
                //center={findCenter(markers)} // disabled until latitude fix is found
                mapTypeControl={false}
                streetViewControl={false}
                fullscreenControl={false}
                onClick={() => onMapClick()}
                class="map"
            >
                { //This is the marker for the user's location
                    !locationButtonEnabled ? <div></div> : 
                    <Marker 
                        title="user"
                        position={props.center}
                        icon={personMarker}
                        onClick={onMarkerClick}
                        name="This is your location"
                    />
                }
                {filteredServices.map((marker, index) => {
                    let isToggledColor = (marker.type === 'info' && props.infoSelected) || (marker.type === 'treat' && props.treatSelected) || (marker.type === 'rec' && props.recSelected)
                   
                    let filter = (markerCategory, filter) => filter.every(val => markerCategory.includes(val))
                    
                    let isInFilter = filter(marker.treatmentType, selectedTreatmentTypes) && filter(marker.population, selectedPopulations) && filter(marker.insurance, selectedInsurances)

                    if(props.takeback || (isToggledColor && isInFilter)){
                        let i = redMarker;
                        if(marker.type === 'treat') i = purpleMarker;
                        else if(marker.type === 'rec') i = blueMarker;

                        return(
                            <Marker 
                                key={index}
                                title={marker.name}
                                name={marker.name}
                                icon={i}
                                position={marker.coords}
                                imageURL={marker.imageURL}
                                address1={marker.address1}
                                address2={marker.address2}
                                phone={marker.phone}
                                website={marker.website}
                                onClick={onMarkerClick}
                            />
                        );
                    }
                    return null;
                })}

                <InfoWindowEx
                    marker={selectedMarker}
                    visible={showInfoWindow}
                    onClose={onMapClick}
                >
                    <div>
                        <span style={{fontWeight: 'bold'}}>
                                {selectedMarker ? selectedMarker.name : ''}
                        </span>
                        {selectedMarker&&selectedMarker.title=="user" ? '' : <div>
                            <br/>
                            <span>
                                {selectedMarker ? selectedMarker.address1 : ''},
                                <br/>
                                {selectedMarker ? selectedMarker.address2 : ''}
                                <br/>
                                Copy Address:<IconButton onClick={onCopyClick(selectedMarker)}><ContentCopyIcon/></IconButton>
                            </span>
                            <br/>
                            <br/>
                            <span>
                                {selectedMarker ? <a href={`tel:+1${selectedMarker.phone}`}>{selectedMarker.phone}</a> : ''}
                                <br/>
                                {selectedMarker ? <a href={`https://${selectedMarker.website}`}>{selectedMarker.website}</a> : ''}
                            </span>
                        </div>}
                    </div>
                </InfoWindowEx>
            </Map>
            <Container className={classes.mapTopButtons} maxWidth='lg'>
                <Button onClick={onLocationClick} style={{width: '50%'}} variant="contained">Go to my location</Button>
                <Button onClick={() => setFiltersOpen(!filtersOpen)} style={{width: '50%'}} variant="contained">
                    Find
                    <SearchIcon/>
                </Button>
            </Container>
            <Container className={classes.mapFilterCard} maxWidth='md'>
                {
                    filtersOpen ? 
                    <Card style={{width: '100%', height: '100%', zIndex: '5'}}>
                        <Container className={classes.mapFilters} maxWidth='md'>
                            <FormControl className={classes.selector}>
                                <Autocomplete
                                    services
                                    options={treatmentTypes}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Treatment Type"
                                        InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                        }}
                                    />
                                    )}
                                    multiple
                                    onChange={(event, newValue) => handleTreatmentTypesSelect(newValue)}
                                />
                            </FormControl>
                            <FormControl className={classes.selector}>
                                <Autocomplete
                                    services
                                    options={populations}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Population Type"
                                        InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                        }}
                                    />
                                    )}
                                    multiple
                                    onChange={(event, newValue) => handlePopulationsSelect(newValue)}
                                />
                            </FormControl>
                            <FormControl className={classes.selector}>
                                <Autocomplete
                                    services
                                    options={insurances}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Insurance Type"
                                        InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                        }}
                                    />
                                    )}
                                    multiple
                                    onChange={(event, newValue) => handleInsurancesSelect(newValue)}
                                />
                            </FormControl>
                            {/* <FormControl className={classes.selector}>
                                <InputLabel>Treatment Type</InputLabel>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={selectedTreatmentTypes}
                                    onChange={handleTreatmentTypesSelect}
                                    input={<Input/>}
                                >
                                    {treatmentTypes.map((type) => {
                                        return (
                                            <MenuItem key={type} value={type}>
                                                {type}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.selector}>
                                <InputLabel>Population</InputLabel>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={selectedPopulations}
                                    onChange={handlePopulationsSelect}
                                    input={<Input/>}
                                >
                                    {populations.map((pop) => {
                                        return (
                                            <MenuItem key={pop} value={pop}>
                                                {pop}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.selector}>
                                <InputLabel>Insurance</InputLabel>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={selectedInsurances}
                                    onChange={handleInsurancesSelect}
                                    input={<Input/>}
                                >
                                    {insurances.map((insure) => {
                                        return (
                                            <MenuItem key={insure} value={insure}>
                                                {insure}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl> */}
                        </Container>
                    </Card>
                    : <></>
                }
            </Container>
            
        </div>
    );
}

/**
 * Displays a Google Map and the associated markers
 * as passed through the `markers` prop
 * 
 * @param {{markers:{name: string, address1: string, address2: string, phone: string, website: string: imageURL: string, county: string, type: string}[], showMarkers: boolean[]}} props
 */
export default GoogleApiWrapper({
  apiKey: (keys.GOOGLE_MAPS_API_KEY)
})(MapContainer);