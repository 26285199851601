const styles = {
    navButton: {
        background: '#418951',
        border: '1em',
        borderRadius: 20,
        boxShadow: '0 3px 5px 2px rgb(156, 196, 164, 1)',
        color: 'white',
        height: '8em',
        width: '8em',
        margin: '1em',
        textAlign: 'center',
        fontSize: '1em',
        "&:hover": {
            background: "#84b395"
        }
    },
    navButton_Wide: {
        background: '#418951',
        border: '1em',
        borderRadius: 20,
        boxShadow: '0 3px 5px 2px rgb(156, 196, 164, 1)',
        color: 'white',
        height: '8em',
        width: '11em',
        margin: '1em',
        textAlign: 'center',
        fontSize: '1em',
        "&:hover": {
            background: "#84b395"
        }
    },
    installButton: {
        background: 'white',
        border: '1em',
        borderRadius: 20,
        boxShadow: '0 0.1em 1em 0.3em rgb(65, 137, 81, 1)',
        color: '#418951',
        height: '8em',
        width: '8em',
        alignItems: 'center',
        margin: '1em',
        fontSize: '1em',
    },
    backButton: {
        color: 'white'
    },
    buttonContent: {
        margin: 'auto',
        textAlign: 'center',
        display: 'block',
    },
    appBar: {
        background: '#418951',
        color: 'white',
        position: 'static'
    },
    secondaryAppBar:{
        background: '#373F47',
        position: 'relative'
    },
    contentPanel: {
        lineHeight: '2em',
        wordWrap: 'break-word',
        textAlign: 'right'
    },
    contentLabel: {
        marginLeft: '1em',
        marginRight: '1em',
        lineHeight: '2em'
    },
    fullPageDiv: {
        paddingTop: '0',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        textAlign: 'center'
    },
    plainLink: {
        textDecoration: 'none'
    },
    tabPanel: {
        paddingTop: '10px'
    },
    selector: {
        display: 'flex',
        justifyContent: 'center',
    },
    mapButtons: {
        display: 'flex',
        position: 'absolute',
        bottom: '15px',
        left: '0',
        right: '0'
    },
    fillerText: {
        color: 'gray',
        textAlign: 'center',
        fontSize: '2em',
        margin: 'auto',
    },
    serviceCard: {
        //backgroundColor: '#373F47',
        //color: 'white',
        display: 'block',
        width: '60%',
        margin: '3em auto',
        padding: '0.5em',
    },
    serviceCardImage: {
        display: 'block',
        backgroundColor: 'white',
        height: '6em',
        objectFit: 'contain',
        margin: '0.5em auto',
    },
    serviceCardTitle: {
        display: 'block'
    },
    mapSelector: {
        position: 'absolute',
        top: '90%',
        height: '10%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    mapSelectorButtonOn: {
        flex: 1,
        color: '#373F47',
    },
    mapSelectorButtonOff: {
        flex: 1,
        color: 'white',
        background: '#373F47'
    },
    mapContainer: {
        position: 'absolute',
        height: '100%',
        width: '100%'
    },
    infoWindow: {
        display: 'flex',
        flexDirection: 'column',
        height: '500px'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    contentAccordion: {
        justifyContent: 'center',
        width: '100%'
    },
    listItemTypography: {
        margin: '1em',
        width: '98%',
        wordWrap: 'break-word',
    },
    listItemPaper: {
        width: '100%',
        wordWrap: 'break-word',
    },
    expandAllButton: {
    },
    mapButton: {
        color: '#ffffff'
    },
    mapTopButtons: {
        display: 'flex',
        position: 'absolute',
        top: '15px',
        left: '0',
        right: '0',
    },
    mapFilterCard: {
        display: 'flex',
        position: 'absolute',
        padding: '15px',
        top: '45px',
        left: '0',
        right: '0'
    },
    mapFilters: {
        padding: '15px'
    },
    listItemImage: {
        width: '150px',
        margin: '1em',
        display: 'inline-block'
    },
    captionedImagePaper: {
        width: '100%',
        wordWrap: 'break-word',
    },
    homeHeaderArea: {
        position: "relative",
	    zIndex: -1,
    },
    imgResponsive: {
        width: "100%",
    },
    backgroundSection1: {
        color: '#310c33',
        background: '#aee9e7',
        background: '-webkit-linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(166, 231, 229, 1) 100%)',
        background: '-o-linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(166, 231, 229, 1) 100%)',
        background: 'linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(166, 231, 229, 1) 100%)',
        transform: "scale(1)",
        paddingBottom: "100px",
    },
    srOnly: {
        position: "absolute",
        width: "1px",
        height: "1px",
        padding: 0,
        margin: "-1px",
        overflow: "hidden",
        clip: "rect(0,0,0,0)",
        border: 0,
    },
    section1White: {
        zIndex: 2,
        position: "relative",
        marginTop: "-150px",
        background: "#ffffff",
        clipPath: "polygon(0 60%, 100% 0, 100% 100%, 0% 100%)",
        padding: "5%",
        minHeight: "200px",
    },
    section2TransparentWhite: {
        zIndex: 2,
        position: "relative",
        marginTop: "-200px",
        background: "#ffffff",
        clipPath: "polygon(0 0, 100% 65%, 100% 100%, 0% 100%)",
        padding: "5%",
        minHeight: "200px",
        opacity: 0.5
    },
    safetyInfoAreaTitle: {
        width: "100",
        padding: "5%",
        zIndex: 10,
        marginTop: "-180px",
    },
    safetyArea: {
        background: "#fff",
        width: 100,
        position: "relative",
        marginTop: "-50px"
    },
    flexContent: {
        display: "flex",
        justifyContent: "center"
    }
}

export default styles;