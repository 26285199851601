import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import keys from "./keys";

const app = firebase.initializeApp({
    apiKey: keys.FIREBASE_API_KEY,
    authDomain: "grow-webapp.firebaseapp.com",
    projectId: "grow-webapp",
    storageBucket: "grow-webapp.appspot.com",
    messagingSenderId: "60333658933",
    appId: "1:60333658933:web:b53ef4ba22e8aab534727c",
    measurementId: "G-HYY6M65XVC"
});

export const database = app.firestore();
export const auth = app.auth();
export default app;
