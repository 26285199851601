import { AppBar, Tabs, Tab, Select, InputLabel, MenuItem, Input, FormControl, Container } from '@material-ui/core';
import { useState } from 'react';
import { Map, List, Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../styles';
import MapContainer from '../MapContainer';
import { Button, ButtonGroup, Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useEffect } from 'react';

const useStyles = makeStyles(styles);

const MapOfServices = ({ content, takeback = false }) => {
    let services = null
    if(!takeback) services = JSON.parse(localStorage.getItem('services'));
    else services = JSON.parse(localStorage.getItem('takeback'));
    // console.log(services, JSON.parse(localStorage.getItem('services')))

    const treatmentTypes = [];
    const populations = [];
    const insurances = [];
    services.map((c) => {
        let newVals = c.treatmentType ? c.treatmentType.filter((type) => !treatmentTypes.find((element) => element === type)) : [];
        treatmentTypes.push(...newVals);
        newVals = c.population ? c.population.filter((pop) => !populations.find((element) => element === pop)) : [];
        populations.push(...newVals);
        newVals = c.insurance ? c.insurance.filter((insure) => !insurances.find((element) => element === insure)) : [];
        insurances.push(...newVals);
        return null;
    });

    const classes = useStyles();
    const [tab, setTab] = useState(1);
    const [selectedTreatmentTypes, setSelectedTreatmentTypes] = useState([]);
    const [selectedPopulations, setSelectedPopulations] = useState([]);
    const [selectedInsurances, setSelectedInsurances] = useState([]);
    const [center, setCenter] = useState({  
        lat: 33.759644,
        lng: -87.450391
    })

    const handleChange = (event, newValue) => {
        setTab(newValue);
    }

    const handleTreatmentTypesSelect = (event) => {
        let newVal = event.target.value;
        setSelectedTreatmentTypes(newVal);
    };

    const handlePopulationsSelect = (event) => {
        let newVal = event.target.value;
        setSelectedPopulations(newVal);
    };

    const handleInsurancesSelect = (event) => {
        let newVal = event.target.value;
        setSelectedInsurances(newVal);
    };

    const [reroute, setReroute] = useState(null);
    const [navMenu, setNavMenu] = useState(false);

    const handleRedirect = event => {
        setReroute(event.currentTarget.name);
    }


    const [infoSelected, setInfoSelected] = useState(true);
    const [treatSelected, setTreatSelected] = useState(true);
    const [recSelected, setRecSelected] = useState(true);

    const handleInfoSelect = (event) => {
        setInfoSelected(!infoSelected)
    }

    const handleTreatSelect = (event) => {
        setTreatSelected(!treatSelected)
    }

    const handleRecSelect = (event) => {
        setRecSelected(!recSelected)
    }

    let filteredServices = services

    if(!takeback) {
        filteredServices = services.filter((service) => {
            var treatFlag = true;
            var popFlag = true;
            var insFlag = true;

            for (var x = 0; x < selectedTreatmentTypes.length; x++){
                treatFlag = (!(service.treatmentType && service.treatmentType.find((type) => selectedTreatmentTypes[x] === type))) ? false : true;
            }
            for (var y = 0; y < selectedPopulations.length; y++){
                popFlag = (!(service.population && service.population.find((pop) => selectedPopulations[y] === pop))) ? false : true;
            }
            for (var z = 0; z < selectedInsurances.length; z++){
                insFlag = (!(service.insurance && service.insurance.find((ins) => selectedInsurances[z] === ins))) ? false : true;
            }

            if (treatFlag && popFlag && insFlag){
                return true;
            }
            return false;
        });
    }

    /*weird bug - header wouldn't be visible upon redirect to this page so this fixes it*/
    useEffect(() => {
        window.scrollTo(0,0)
        document.body.style.overflow = 'hidden'
        return () => document.body.style.overflow = 'scroll'
    })

    return(
        reroute ?
        <Redirect push to={reroute}/>
        :
        <div>

            {/*----- HEADER AREA: Since I'm not sure how you'll code the  menu bits, I've just left things plain and 'open' without any of the linkage/javascript. Normally the 'navigation-menu' area will be hidden until 'fa-bars' is clicked. 'logo-area' is for the homepage link to be added.-----*/}
                <div class="header-area">
                <a name="landing" onClick={(e) => handleRedirect(e)}><div class="logo-area"></div></a>
                {content ? (content.navigationItems ? <a onClick={()=>setNavMenu(!navMenu)}><i class="fa-solid fa-bars"></i></a> : <></>) : <></>}
            </div> {/*- .header-area -*/}
                
            {navMenu ? <div class="navigation-menu">
                <ul>
                    {content.navigationItems && content.navigationItems.map((item) => {
                        return(
                            <li>
                                <a name={item.path} onClick={(e) => handleRedirect(e)}>{item.title}</a>
                            </li>
                        )
                    })}
                </ul>
            </div> : <></> /*- .navigation-menu -*/}

            <MapContainer
                // markers={filteredServices}
                infoSelected={infoSelected}
                treatSelected={treatSelected}
                recSelected={recSelected}
                takeback={takeback}
                center={center}
                setCenter={setCenter}
            />
            
            {takeback ? <></> : <Container className={classes.mapButtons} maxWidth='xl'>
                <Button onClick={handleInfoSelect} style={infoSelected ? {backgroundColor: '#ff0404', width: '33%'} : {backgroundColor: '#d3d3d3', width: '33%'}} variant="contained">
                    <Typography className={classes.mapButton}>Support Services</Typography>
                </Button>
                <Button onClick={handleTreatSelect} style={treatSelected ? {backgroundColor: '#5d34b0', width: '33%'} : {backgroundColor: '#d3d3d3', width: '33%'}} variant="contained">
                    <Typography className={classes.mapButton}>Treatment</Typography>
                </Button>
                <Button onClick={handleRecSelect} style={recSelected ? {backgroundColor: '#049cf4', width: '33%'} : {backgroundColor: '#d3d3d3', width: '33%'}} variant="contained">
                    <Typography className={classes.mapButton}>Recovery</Typography>
                </Button>
            </Container>}
        </div>
    );
}

export default MapOfServices;