import './App.css';
import 'fontsource-roboto';
import Landing from './Components/Pages/Landing';
import {Route, BrowserRouter, Redirect, HashRouter} from 'react-router-dom';
import SecondaryPage from './Components/Pages/SecondaryPage';
import styles from './grow-app.css'
import MapOfServices from './Components/Pages/MapOfServices';
import { useState, useEffect } from 'react';
import { database } from './firebaseConfig.js';

function App() { 

  const [landing, setLanding] = useState(null)
  const [secondaryPages, setSecondaryPages] = useState(null)
  const [updateForcer, setUpdateForcer] = useState(false)


  // Cache/Update Local Services Data
  var servicesData = [];
  var services_version_local = localStorage.getItem('services_version');
  var services_version_remote = -1;

  database.collection('versions').doc("ServicesVersion").get().then( (doc) => {
    services_version_remote = doc.data().version;
    //console.log("services versions: local ", services_version_local, " remote ", services_version_remote)

    if ( ! ('services' in localStorage) || ! ('services_version' in localStorage) || (services_version_remote != services_version_local)){
      localStorage.setItem('services_version', JSON.stringify(services_version_remote));
      updateServicesData();
    }
  })


  function updateServicesData() {
    database.collection('services').get().then( (query) => {
        query.forEach( (doc) => {
            if (doc.id !== 'version')
              servicesData.push(...[doc.data()]);
        })
        localStorage.setItem('services', JSON.stringify(servicesData));
    })
  }

  // Cache/Update Local Takeback Locations Data
  var takebackData = [];
  var takeback_version_local = localStorage.getItem('takeback_version');
  var takeback_version_remote = -1;

  database.collection('versions').doc("TakebackVersion").get().then( (doc) => {
    takeback_version_remote = doc.data().version;
    //console.log("takeback versions: local ", takeback_version_local, " remote ", takeback_version_remote)

    if ( ! ('takeback' in localStorage) || ! ('takeback_version' in localStorage) || (takeback_version_remote != takeback_version_local)){
      localStorage.setItem('takeback_version', JSON.stringify(takeback_version_remote));
      updateTakebackData();
    }
  })


  function updateTakebackData() {
    database.collection('takebacklocations').get().then( (query) => {
        query.forEach( (doc) => {
            if (doc.id !== 'version')
              takebackData.push(...[doc.data()]);
        })
        localStorage.setItem('takeback', JSON.stringify(takebackData));
    })
  }

  // Cache/Update Local Landing Page Content
  var landingContent = [];
  var landing_version_local = localStorage.getItem('landing_version');
  var landing_version_remote = -1;

  database.collection('versions').doc("LandingVersion").get().then( (doc) => {
    landing_version_remote = doc.data().version;
    // console.log("landing versions: local ", landing_version_local, " remote ", landing_version_remote)
    // console.log((landing_version_remote != landing_version_local))
    if ( ! ('landing' in localStorage) || ! ('landing_version' in localStorage) || (landing_version_remote != landing_version_local)){
      console.log("updating landing")
      localStorage.setItem('landing_version', JSON.stringify(landing_version_remote));
      updateLandingData();
    }
  })


  function updateLandingData() {
    //console.log("update landing")
    database.collection('landing').get().then( (query) => {
        query.forEach( (doc) => {
            if (doc.id !== 'version')
              landingContent.push(...[doc.data()]);
        })
        localStorage.setItem('landing', JSON.stringify(landingContent));
        setLanding(JSON.parse(localStorage.getItem('landing')));
    })
  }

  // Cache/Update Local Secondary Pages Content
  var secondaryContent = [];
  var secondary_version_local = localStorage.getItem('secondary_version');
  var secondary_version_remote = -1;

  database.collection('versions').doc("SecondaryPagesVersion").get().then( (doc) => {
    secondary_version_remote = doc.data().version;

    if ( ! ('secondaryPages' in localStorage) || ! ('secondary_version' in localStorage) || (secondary_version_remote != secondary_version_local)){
      localStorage.setItem('secondary_version', JSON.stringify(secondary_version_remote));
      updateSecondaryPagesData();
    }
  })


  function updateSecondaryPagesData() {
    database.collection('secondaryPages').get().then( (query) => {
        query.forEach( (doc) => {
            if (doc.id !== 'version')
              secondaryContent.push(...[doc.data()]);
        })
        localStorage.setItem('secondaryPages', JSON.stringify(secondaryContent));
        setSecondaryPages(JSON.parse(localStorage.getItem('secondaryPages')));
    })
  }



  const getLanding = async () => {
    setLanding(await JSON.parse(localStorage.getItem('landing')));
  }

  const getSecondary = async () => {
    setSecondaryPages(await JSON.parse(localStorage.getItem('secondaryPages')));
  }

  useEffect(() => {
    getLanding()
    getSecondary()
  }, [])

  return (
    <BrowserRouter>
      <Route exact path='/'
        render={(props) => (
          <Landing content={landing}/>
        )}
      />
      <Route exact path='/landing'
        render={(props) => (
          <Landing content={landing}/>
        )}
      />
      <Route exact path='/map'
        render={(props) => (
          <MapOfServices label='Services' content={secondaryPages ? secondaryPages.find(obj => obj.path === 'map') : null}/>
          // <SecondaryPage content={secondaryPages.find(obj => obj.path === 'map')}/>
        )}
      />
      <Route exact path='/takeback-locations'
        render={(props) => (
          <MapOfServices label='Services' takeback content={secondaryPages ? secondaryPages.find(obj => obj.path === 'takeback-locations') : null}/>
        )}
      />
      
      {secondaryPages ? secondaryPages.map(page => {
        //console.log(page.path, typeof(page.path))
        return (<Route exact path={"/"+page.path} key={page.path}
          render={() =>
            <SecondaryPage content={page} copyright={landing[0].about.copyright} feedbackEmail={landing[0].about.feedbackEmail} feedbackText={landing[0].about.feedbackText}/>
          }
        />)
      }) : <></>}
    </BrowserRouter>
  );
}

export default App;
