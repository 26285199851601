import {Button} from '@material-ui/core';
import styles from './../styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

/**
 * A button for navigation from the landing page
 * 
 * @param {{name: string, onClick: function}} props
 */
const NavButton = (props) => {
    const classes = useStyles();

    return(
        <Button
            name={props.name}
            className={props.class}
            onClick={props.onClick}
        >
            <div className={classes.buttonContent}>
                {props.children}
            </div>
        </Button>
    );
}

export default NavButton;