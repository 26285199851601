import { Redirect } from 'react-router-dom';
import { useState } from 'react';
import GrowAppTop3 from '../../Media/Grow-app-top-3.png'
import WhiteWave4 from '../../Media/white-wave-4.png'
import { Container } from '@material-ui/core';
import { marked } from 'marked';

//Struggling to get plugins to work here.
//First, check rightmost tab of GROW group, else follow below
//Either move to JS markdown compiler
//OR use MDX plugin and instruct team to use html hrefs

function phoneNumbers (url) {
    console.log(url)
}

const SecondaryPage = ({ content, copyright, feedbackEmail, feedbackText }) => {
    const [reroute, setReroute] = useState(null);
    const [navMenu, setNavMenu] = useState(false);

    const handleRedirect = event => {
        setReroute(event.currentTarget.name);
    }
    
    if(!content) {
        return <></>
    } 

    let seperatedContent = []

    if(content.content) {
        for(let i=0; i<content.content.length; i++) {
            let innerArr = []
            while(content.content[i] && content.content[i].contentType != "banner") {
                innerArr.push(content.content[i])
                i++
            }
            if(innerArr.length!=0) seperatedContent.push(innerArr)
            if(content.content[i] && content.content[i].contentType == "banner") {
                seperatedContent.push([content.content[i]])
            }
        }
    }
    // console.log(seperatedContent)

    return(
        reroute ?
        <Redirect push to={reroute}/>
        :
        <> 
            {/*----- HEADER AREA: Since I'm not sure how you'll code the  menu bits, I've just left things plain and 'open' without any of the linkage/javascript. Normally the 'navigation-menu' area will be hidden until 'fa-bars' is clicked. 'logo-area' is for the homepage link to be added.-----*/}
            <div class="header-area">
                <a name="landing" onClick={(e) => handleRedirect(e)}>
                    <div class="logo-area"></div>
                </a>
                {content.navigationItems ? <a onClick={()=>setNavMenu(!navMenu)}><i class="fa-solid fa-bars"></i></a> : <></>}
            </div> {/*- .header-area -*/}
                
            {navMenu ? <div class="navigation-menu">
                <ul>
                    {content.navigationItems && content.navigationItems.map((item) => {
                        return(
                            <li>
                                <a name={item.path} onClick={(e) => handleRedirect(e)}>{item.title}</a>
                            </li>
                        )
                    })}
                </ul>
            </div> : <></> /*- .navigation-menu -*/}
                
            <div class="page-header">
                <h1>{content.pageTitle}</h1>
                <img src={GrowAppTop3} alt="White Wave Separator"/>
            </div> {/*- .page-header -*/}

            <div class="eraser-line"></div> {/*- NOTE: This has to be below all instances of 'page-header'. It covers a colorline between the next white background and the 'grow-app-top-3.png'. -*/}
              
            { 
                seperatedContent ? <>
                    {seperatedContent.map((contentBlock) => {
                        if(contentBlock[0].contentType == "banner" && contentBlock[0].dark) {
                            return <>
                                <div class="colorful-spacer-box dark-background">
                                    {/*<p>GROW ALABAMA GROW ALABAMA GROW ALABAMA GROW ALABAMA GROW ALABAMA GROW ALABAMA GROW ALABAMA GROW ALABAMA GROW ALABAMA</p>*/}
                                    <p>How to talk to teens about opioids. How to talk to teens about opioids. How to talk to teens about opioids. How to talk to teens about opioids</p>
                                    <img src={WhiteWave4}  alt="White Wave Separator" class="colorful-spacer-box-wave" />
                                </div>
                                <div class="colorful-spacer-box-connect"></div> 
                            </>
                        } else if(contentBlock[0].contentType == "banner") {
                            return <>
                                <div class="colorful-spacer-box green-background">
                                    <p class="transparent-text">GROW ALABAMA GROW ALABAMA GROW ALABAMA GROW ALABAMA GROW ALABAMA GROW ALABAMA GROW ALABAMA GROW ALABAMA GROW ALABAMA</p>
                                    <img src={WhiteWave4}  alt="White Wave Separator" class="colorful-spacer-box-wave" />
                                </div>
                                <div class="colorful-spacer-box-connect"></div> 
                            </>
                        } else {
                            return <div class="main-content">
                                {contentBlock.map((c) => {
                                    if(c.contentType=="heading"){
                                        if(c.headingType=="heading2"){
                                            return (
                                                <Container maxWidth="lg">
                                                    <h2>{c.heading}</h2>
                                                </Container>
                                            )
                                        } else {
                                            return (
                                                <Container maxWidth="lg">
                                                    <h3>{c.heading}</h3>
                                                </Container>
                                            )
                                        }
                                    } else if(c.contentType=="paragraph"){
                                        return(
                                            <Container maxWidth="lg">
                                                {/* <p>{c.text}</p> */}
                                                {/* <Markdown remarkPlugins={[remarkRehype()]}>
                                                    {c.text}
                                                </Markdown> */}
                                                {c.text ? <div dangerouslySetInnerHTML={{__html: marked.parse(c.text)}} /> : <></>}
                                            </Container>
                                        )
                                    } else if(c.contentType=="featuredParagraph"){
                                        let background="light-background"
                                        if(c.dark) {
                                            background="dark-background"
                                        }
                                        return (
                                            <Container maxWidth="lg">
                                                <div class="featured-box light-background">
                                                    {/* <Markdown remarkPlugins={[myRemarkTelephonePlugin, remarkRehype]}>
                                                        {c.text}
                                                    </Markdown> */}
                                                    {c.text ? <div dangerouslySetInnerHTML={{__html: marked.parse(c.text)}} /> : <></>}
                                                    <ol>
                                                        {c.list ?
                                                            c.list.map((listItem) => {
                                                                return(
                                                                <li>{listItem}</li>
                                                                )
                                                            }) : <></>
                                                        }
                                                    </ol>
                                                </div>
                                            </Container>
                                        )
                                    } else if(c.contentType=="list"){
                                        return(
                                            <Container maxWidth="lg">
                                                <ul>
                                                    {c.list ?
                                                        c.list.map((listItem) => {
                                                            return(
                                                                <li>{listItem}</li>
                                                            )
                                                        }) : <></>
                                                    }
                                                </ul>
                                            </Container>
                                        )
                                    } else if(c.contentType=="banner"){
                                        return(
                                            <>
                                                <div class="colorful-spacer-box green-background">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor inci didunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                                                    <img src={WhiteWave4}  alt="White Wave Separator" class="colorful-spacer-box-wave" />
                                                </div> {/*- .colorful-spacer-box -*/}
                                                <div class="colorful-spacer-box-connect"></div> 
                                            </>
                                        )
                                    }
                                })
                            } </div>
                        }
                    })
                } </> : <></>
            }

            <div class="secondary-footer">
                <h2>{copyright}</h2>
                <a href={"mailto:"+feedbackEmail+"?subject = GROW Feedback&body = Accessed from landing page. Please let us know what you think of the app!"}>
                    <h4>
                        {feedbackText}
                    </h4>
                </a>
            </div>
        </>
    )
}

export default SecondaryPage