import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ReactPWAInstallProvider from "react-pwa-install";
import { database } from './firebaseConfig.js';
import ReactGA from 'react-ga4';
import keys from './keys'

// Cache/Update Local Services Data
var servicesData = [];
var services_version_local = localStorage.getItem('services_version');
var services_version_remote = -1;

database.collection('versions').doc("ServicesVersion").get().then( (doc) => {
  services_version_remote = doc.data().version;

  if ( ! ('services' in localStorage) || ! ('services_version' in localStorage) || (services_version_remote != services_version_local)){
    localStorage.setItem('services_version', JSON.stringify(services_version_remote));
    updateServicesData();
  }
})


function updateServicesData() {
  database.collection('services').get().then( (query) => {
      query.forEach( (doc) => {
          if (doc.id != 'version')
            servicesData.push(...[doc.data()]);
      })
      localStorage.setItem('services', JSON.stringify(servicesData));
  })
}

// Cache/Update Local Takeback Locations Data
var takebackData = [];
var takeback_version_local = localStorage.getItem('takeback_version');
var takeback_version_remote = -1;

database.collection('versions').doc("TakebackVersion").get().then( (doc) => {
  takeback_version_remote = doc.data().version;

  if ( ! ('takeback' in localStorage) || ! ('takeback_version' in localStorage) || (takeback_version_remote != takeback_version_local)){
    localStorage.setItem('takeback_version', JSON.stringify(takeback_version_remote));
    updateTakebackData();
  }
})


function updateTakebackData() {
  database.collection('takebacklocations').get().then( (query) => {
      query.forEach( (doc) => {
          if (doc.id != 'version')
            takebackData.push(...[doc.data()]);
      })
      localStorage.setItem('takeback', JSON.stringify(takebackData));
  })
}

// Cache/Update Local Landing Page Content
var landingContent = [];
var landing_version_local = localStorage.getItem('landing_version');
var landing_version_remote = -1;

database.collection('versions').doc("LandingVersion").get().then( (doc) => {
  landing_version_remote = doc.data().version;

  if ( ! ('landing' in localStorage) || ! ('landing_version' in localStorage) || (landing_version_remote != landing_version_local)){
    localStorage.setItem('landing_version', JSON.stringify(landing_version_remote));
    updateLandingData();
  }
})


function updateLandingData() {
  database.collection('landing').get().then( (query) => {
      query.forEach( (doc) => {
          if (doc.id != 'version')
            landingContent.push(...[doc.data()]);
      })
      localStorage.setItem('landing', JSON.stringify(landingContent));
  })
}

// Cache/Update Local Secondary Pages Content
var secondaryContent = [];
var secondary_version_local = localStorage.getItem('secondary_version');
var secondary_version_remote = -1;

database.collection('versions').doc("SecondaryPagesVersion").get().then( (doc) => {
  secondary_version_remote = doc.data().version;

  if ( ! ('secondaryPages' in localStorage) || ! ('secondary_version' in localStorage) || (secondary_version_remote != secondary_version_local)){
    localStorage.setItem('secondary_version', JSON.stringify(secondary_version_remote));
    updateSecondaryPagesData();
  }
})


function updateSecondaryPagesData() {
  database.collection('secondaryPages').get().then( (query) => {
      query.forEach( (doc) => {
          if (doc.id != 'version')
            secondaryContent.push(...[doc.data()]);
      })
      localStorage.setItem('secondaryPages', JSON.stringify(secondaryContent));
  })
}

ReactGA.initialize(keys.GOOGLE_ANALYTICS_MEASUREMENT_ID);

ReactDOM.render(
  <React.StrictMode>
    <ReactPWAInstallProvider>
      <App />
    </ReactPWAInstallProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
